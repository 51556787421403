// .black {
//   font-size: 13px;
//   padding: 20px;
//   margin: 10px !important;
// }



.css-bsph2u-MuiTableCell-root {
  margin-top: 2px;
  padding: 0 !important;
}
.leftpan-container{
  .link {
    color:blue  !important;
        }

  .active_link {
    color: red !important;
       }
  .box-container{
     padding :20px;
    }
  .margintop{
      margin-top:2px;
    }
  .height{
    height: 30px;
      }
  .black_left_menu{
    font-family:Verdana, Arial, Helvetica, sans-serif;
    font-size:11px;
    line-height:22px;
    text-decoration:underline;
    color: blue !important;
}
}