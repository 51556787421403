.tel-list-padding {
  padding: 0 0 5px 0;
}

.contents-row {
  text-align: left;
  display: flex;
}
.content-padding{
  padding-top: 5px;
}
.text-area {
  width: 98%;
  font-size: 12px;
}


.input-select {
  width: 20%;
  margin: 0 0 1% 7%;
  font-size: 12px;
  color: #000000;
  border: 1px solid #4A7EBB;
  position: relative;
  border-radius: 0rem;
  height: 4%;
}

.input-select:disabled {

  color: #000000 !important;
  opacity: 1;
}

.input-select-name {

  width: 20%;
  margin: 0 0 1% 1%;
  font-size: 12px;
  color: #000000;
  border: 1px solid #4A7EBB;
  position: relative;
}
.text{
  font-size: 12px;
}

.label1 {
  margin-right: 3px;
}

.text_input {
  width: 95px;
  color: #000000;
  font-size: 12px;
  border: 1px solid #4A7EBB;
  height: 16px;
  border-radius: 0px;

}

.text_input:disabled {
  color: #000000 !important;
  opacity: 1;
}


.middles_table {
  border: 1px solid #99B3D2;

}

.input-take {
  margin-left: 2%;
  height: 5%;
  width: 60%;
  font-size: 12px;
  color: #000000;
}

.main_container {
  height: 100%;
}

form#telConsultation label {

  font-weight: 400;
  color: #080808;

}


.button-size {
  width: 120px;
  height: 60px;

  img {
    width: 100%;
    height: auto;
  }
}

.button-sizes {
  width: 80px;
  height: 40px;

  img {
    width: 100%;
    margin-top: 15px;
    height: auto;
  }
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-wrapper {
  display: flex;
  align-items: left;
  font-size: 24px;
}

.parenthesis {
  display: inline-block;
  font-size: 10px;
  margin: 0 5px;
}

.input-field {
  border: none;
  outline: none;
  font-size: 10px;

  text-align: center;
  width: 80px;
}

.custom-select {
  .MuiOutlinedInput-root {
    border-radius: 0px !important;
    height: 14px !important;
  }

  .MuiSelect-select {
    border-radius: 0px !important;
    padding: 0px !important;
    line-height: 12px !important;
    font-size: 12px;
    height: 14px !important;
    width: 95px;
    color: #000000;
    border: 1px solid#4A7EBB;

  }

  // Style for the dropdown arrow
  .MuiSelect-icon {
    position: absolute;
    right: 4px;

    color: #000000;
  }

  .MuiSelect-icon {
    transform: none !important;
    transition: none !important;
  }
}

.placeholder-text {
  color: #aaa;
  font-style: italic;
}

.margin{
  font-size: 12px;
  padding-right: 8px;
}

.left-aligned-text {
  color: #000000;
  justify-content: left;
  font-size: 12px;
  padding: 1px 1px 0px 3px;
  display: flex;
}