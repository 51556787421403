

$gridBorderWidth: 1px;
$tableBorderWidth: 1px;
$editgridBorderWidth: 0.2px;
$gridBorderColor: #4b4b4b;
$tableBorderColor: #afadad; 

@mixin addBorderToGrid {
  border: $gridBorderWidth solid $gridBorderColor;
}
@mixin addBorderToEditGrid {
  border: $editgridBorderWidth solid $gridBorderColor;
}

@mixin addBorderToTable {
  border: $gridBorderWidth solid $tableBorderColor;
}
.Grid-root {
  @include addBorderToGrid;
  border-color: #4b4b4b !important;
  &-border-left{
    border-left: $gridBorderWidth solid $gridBorderColor;
  }
  &-border-top{
    border-top: $gridBorderWidth solid $gridBorderColor;
  }
  &-border-right{
    border-right: $gridBorderWidth solid $gridBorderColor;
  }
  &-border-bottom{
    border-bottom: $gridBorderWidth solid $gridBorderColor;
  }
  &-border-top-bottom{
    height: 30px;
    border-bottom: $gridBorderWidth solid $gridBorderColor;
    border-top: $gridBorderWidth solid $gridBorderColor;
  }
  label{
    padding: 2px;
    display: inline-block;
    font-family: 'Times New Roman';
    font-size: 11px 
  }
  
}
.Table-root {
  @include addBorderToTable;
  &-border-left{
    border-left: $tableBorderWidth solid $tableBorderColor;
  }
  &-border-top{
    border-top: $tableBorderWidth solid $tableBorderColor !important;
  }
  &-border-right{
    border-right: $tableBorderWidth solid $tableBorderColor;
  }
  &-border-bottom{
    border-bottom: $tableBorderWidth solid $tableBorderColor !important;
  }
  &-border-right-bottom{
    border-right: $tableBorderWidth solid $tableBorderColor;
     border-bottom: $tableBorderWidth solid $tableBorderColor !important;
  }
  label{
    padding: 5px;
    display: inline-block; 
    font-family:'Times New Roman';
     
  }
  .headerbold{
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }
  &-col0{
    width:5%
  }
  &-col1{
width:1%
  }
   &-col2{
width:7%
  }
   &-col3{
width:8%
  }
   &-col4{
width:7%
  }
   &-col5{
width:10%
  }
   &-col6{
width:4%
  }
   &-col7{
width:1%
  }
   &-col8{
width:10%
  }
   &-col9{
width:14%
  }
   &-col10{
width:5%
  }
   &-col11{
width:5%
  }
   
  
}
.edit-grid-root {
     height: 35px;
     padding: 0 !important;
     
     @include addBorderToEditGrid;

  &-border-left{
    border-left: $editgridBorderWidth solid $gridBorderColor ;
  }
  &-border-top{
     border-top: $editgridBorderWidth solid $gridBorderColor;
  }
  &-border-right{
    border-right: $editgridBorderWidth solid $gridBorderColor;
  }
  &-border-bottom{
     border-bottom: $editgridBorderWidth solid $gridBorderColor ;
  }
  
  &-input{
    width: 90% ;
    margin:2px ;
    
  }
  &-column-header{
    padding: 0 !important;
    justify-items: center;
    font-weight: bold;
    justify-content: center !important;
    font-size:16px;
    border: 0.3px solid #4b4b4b;
    border-color: #4b4b4b !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &-grid-design{
    border-color: #4b4b4b !important;
    
  }
}

.MuiDataGrid-columnHeader, .css-k3hz9t-MuiDataGrid-root .MuiDataGrid-cell{
  padding: 0 !important;
  
}

 
.rmbtn {
  background-color: red !important;
  border-radius: 34px !important;
  border-color: #000000 !important;
  border: 2px solid #000000 !important;
  height: 50%;
  width: 80% !important;
   color: #000000 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.p2red_bg_midle {
  height: 30px;
  width: 100%;
 
  & .black {
    font-size: 15px;
    padding: 10px;
    margin-top: 1px;
  }
}

.first_page {
 //margin: 13px;
 color:#000000;
 font-size: 16px;
   .boxDesign {
    
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .history_page {
    font-size: 16px;
    margin-top: 40px !important;
    padding: 5px;
    border: 1px solid black;
    font-family: 'Times New Roman';
  }

  .downloadbtn {
    background-color: #349ACF;
    border-radius: 36px;
    border-color: #349ACF;
    color: white;
    padding: 5px 40px;
    text-align: center;
    margin-left: 20px;
  }
}
.gridContainer{
   min-height: 120px;
    
}
.grid-container-detail{
  min-height: 50px;
}
.grid-container-biography{
  min-height: 75px;
}
.button-style{
  background-color:#349ACF;
  border-radius:36px;
  border-color:#349ACF;
   color: white;
   padding: 5px 40px;
   text-align: center; 

}
.buttoncontainer{
  margin-right: 7px;
}
.grid-button-container{
  flex-direction: row;
  padding: 5px;
}
.padding{
padding: 2px;
 display: inline-block; 
}
.even-row {
  background-color: #ffd8a7;
}

.odd-row {
  background-color: lightblue;
}
.custom-row {
  height: 1px !important;
  background-color: white;
  padding: 5px !important;
}
.cell-padding {
  padding: 2px !important;
  font-size: 14px;
  font-family: 'Times New Roman';
}

.customRow {
 
    border-bottom: 2px solid #afadad !important;
  
  }
  .page-break {
  page-break-after: always;
  
  
}
.grid-main-container{
  margin-top: 15px;
}
@media print {
  .button-style {
    display: none;
  }
  .headerstyle{
    display: none;
  }
}