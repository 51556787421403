
// ===========================<Morita nervous>======================================
.createbutton {
  background-color: #800000 !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  padding: 5px 15px !important;
  text-align: center !important;
}
.row_table_list_c1{
  background-color:#dce8ff;
  &:hover {
    background-color: #dce8ff !important; 
    color: inherit; 
  }
  &::selection{
    background-color: #dce8ff !important; 
    color: inherit; 
  }
}

.row_table_list_c2{
  background-color:white;
  &:hover {
    background-color:white !important; 
    color: inherit; 
  }
  &::selection{
    background-color:white !important; 
    color: inherit; 
  }
  
}


//==========================<email Recption style>======================================
.emailReceptionButton {
  background-color: #349acf;
  border-radius: 5px;
  border-color: #349acf;
  color: white;
  padding: 5px 25px;
  text-align: center;

  width: 120px;
}

//===========================<past history style>======================================

.past_border_padding{

padding: 8px;

}
//===========================<Daily mail style>=====================================

//reservationlist style
.mbr-list-bg1{
  background-color:#D0D8E8;
  &:hover {
    background-color: #D0D8E8 !important; 
    color: inherit; 
  } 
 }
 .mbr-list-bg2{
  background-color:#E9EDF4;
  &:hover {
    background-color: #E9EDF4 !important; 
    color: inherit; 
  } 
 }
 .gridcolumn-cells {
  border: 1px solid #ffff !important;
  padding: 1px !important;

}
 .button_grid{
  flex-direction: row;
  display: flex;
  justify-content:space-evenly;
 }
 .checkemail_div{
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.2;
  text-align: center;
 }
.table-count-header-bg{
  background-color: #4F81BD; 
  color: white;
  font-weight: bold;
  text-align: center;
  text-align: center;
  height: 10px;
}



.table-count-header-border{
  border-bottom: 2px solid #729BCB;
  border-left: 2px solid #729BCB;
}

.table-count-header-width-col1{

  width: 106px;


}
.table-count-header-width-col2{

  width: 130px;

}

.table-count-header-width-col3{

  width: 93px;

}
.table-count-header-width-col4{
  width: 135px;

}

.table-count-header-width-col5{
  width: 135px;

}
.table-count-header-width-col6{
  width: 112px;

}
.table-count-header-width-col7{
  width: 175.5px;

}
.table-center-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}


.table-count-text-style{
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  // text-align: center;
  // align-items: center;
  // padding-left: 40px;
  color: white;
}
 .lftbtn_style {
  background-color: #4F81BD;
  border-radius: 36px;
  border-color: #349ACF;
  color: white;
  padding: 5px 25px;
  text-align: center;
}
.btnmrglft{
margin-left: 22px;
}
.gridmrglft{
margin-left: 40px !important;
}

.checkCell-list-bg1{
  background-color:#E1EFFF;
  &:hover {
    background-color: #E1EFFF !important; 
    color: inherit; 
  } 
 }
 .checkCell-list-bg2{
  background-color:#ffffff;
  &:hover {
    background-color:#ffffff !important; 
    color: inherit; 
  } 
 }

 .pad-left {
  padding-left: 5px ; 
}
//responsetemplate
.buttonstyle{
  background-color: #349ACF !important;
    border-radius: 5px;
    border-color: #349ACF !important;
    color: white;
    padding: 5px 20px;
    height: 30px;
    text-align: center;
    width: 120px;
}
.margn-left{
  margin-left: 22px !important;
}
.response-temp-margintop{
  margin-top: 22px ;
}
.response-tmp-content-row{
  display: flex;
  flex-direction: row;
  padding:10px
}

// e1efff

.daily_table_list_c1{
  background-color:#e1efff;
  &:hover {
    background-color: #e1efff !important; 
    color: inherit; 
  }
}
.next_button{
  background-color: #349ACF;
  border-radius: 36px;
  border-color: #349ACF;
  color: white;
  padding: 5px 25px;
  text-align: center;
}
.select_box{
font-size:12px;
color:#000000;
border:1px solid #4A7EBB;
width: 300px;
height: 25px;

}

.checkCell-list-bg-total{
  background-color:#4F81BD;
  color: white;
  &:hover {
    background-color:#4F81BD !important; 
    color: white;
    color: inherit; 
  } 
 }
// ===========================<ResponseTemplate>==============================

.new_template_button_align{
  padding-left: .15rem;
}
.btnstyle{
  background-color: #4F81BD !important;
    border-radius: 36px !important;
    border-color: #349ACF !important;
    color: white;
    padding: 5px 25px;
    text-align: center;
}
.custom-date-text{
  color: blue;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.mgleft{
  margin-left: 20px;
}

.defaultButton{
  background-color: #349ACF!important;
  border-radius: 5px;
  border-color: #349ACF!important;
  color: white;
  padding: 5px 25px;
  text-align: center;
  size: 20px;
  width: 200px;
}



.response_template_deviceType_selected{
color: red;
cursor: pointer;  
}

.response_template_deviceType_notSelected{
  color: black;
  cursor: pointer;
  text-decoration: underline;
}
.response_flex_end{
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;

}
.row-content-padding{
  padding: 3px !important;
}

.hidden-datepicker {
  display: none;
}

.edit_btn {
  width: 75px; 
  background-color: #34699e !important; 
  background-repeat: no-repeat;
  color: white; 
  border: none;
 
}
.black2_link {
  color: rgb(7, 7, 7); /* Adjust the link color if you want it to be visible */
}
 .cursor_pointer{
    cursor: pointer;
  }

.gridcolumn-cell {
  display: flex;
  
  border: 1px solid #ffff !important;
 
    
  }
  .black_txt{
    font-family:Verdana, Arial, Helvetica, sans-serif;
    color: #000000;
    font-size:12px;
    }

.check_date{
  padding-left: 30px;
}